import React, { Fragment, useContext, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { I18nContext } from '../providers/I18nProvider'
import { ModalDialog } from './ModalDialog'
import { CookieNames } from '../types/cookieNames'

export const CookiePolicy: React.FC = () => {
  const { translate } = useContext(I18nContext)
  const [show, setShow] = useState(!document.cookie.includes(`${CookieNames.COOKIE_CONSENT}=true`))

  const setCookieConsentTrue = () => {
    const expires = new Date()
    expires.setFullYear(expires.getFullYear() + 1)

    document.cookie = `${CookieNames.COOKIE_CONSENT}=true; path=/; expires=${expires.toUTCString()}; SameSite=Lax`
  }

  const showCookiePolicy = () => {
    window.open('/static/privacy_policy.pdf', '_blank');
  }

  return (
    <Fragment>
      <ModalDialog
        show={show}
        titleText={translate('cookieConsent.title')}
        bodyText={translate('cookieConsent.message')}
        continueText={translate('cookieConsent.buttonText')}
        secondaryButtonText={translate('cookieConsent.policyLink')}
        onSubmit={() => {
          setCookieConsentTrue()
          setShow(false)
        }}
        onClickSecondaryButton={showCookiePolicy}
      />
    </Fragment>
  )
}
