import React, { createContext, useContext, useState } from 'react'
import { authAxios } from '../util/api'
import { Alert, Button, Modal } from 'react-bootstrap'
import { I18nContext } from './I18nProvider'
import axios, { AxiosInstance } from 'axios'
import { AuthContext } from './AuthProvider'
import { LOGIN } from '../util/goTo'
import { useNavigate } from 'react-router-dom'

interface ErrorMessage {
  text: string
  link?: string
  callToAction?: string
  closeLink?: string
}

export const RestErrorHandlingContext = createContext<{ axios: AxiosInstance }>({ axios: axios.create() })
RestErrorHandlingContext.displayName = 'RestErrorHandlingContext'

export const RestErrorHandlingProvider = ({ children }) => {
  const axios = authAxios()
  const navigate = useNavigate()
  const { logout } = useContext(AuthContext)
  const [errors500, setErrors500] = useState([])
  const [errors400, setErrors400] = useState<Set<string>>(new Set())
  const [serverDoesNotRespond, setServerDoesNotRespond] = useState(false)
  const { translate } = useContext(I18nContext)

  axios.interceptors.response.use(
    c => c,
    error => {
      switch (error.response?.status) {
        case 500: {
          const errors = error.response.data?.errors ?? [error.response.data?.error]
          errors?.forEach(e => console.error(e))
          setErrors500(prevState => [...prevState, error.response.data])
          return Promise.reject(error)
        }
        case 502: {
          setServerDoesNotRespond(true)
          return Promise.reject(error)
        }
        case 400:
        case 402:
        case 409:
        case 429: {
          const errors = error.response.data?.errors ?? [error.response.data?.error]
          const errorsJson = errors?.map(error => JSON.stringify(error))
          console.warn('Server returned 4**:', errors)
          setErrors400(prevState => new Set([...prevState, ...errorsJson]))

          return Promise.reject(error)
        }
        case 403: {
          logout(LOGIN)
          return Promise.reject(error)
        }
        default: {
          return Promise.reject(error)
        }
      }
    }
  )

  return (
    <RestErrorHandlingContext.Provider value={{ axios }}>
      {!serverDoesNotRespond && children}

      <Modal show={errors400?.size > 0} centered className="px-3 text-break">
        <Alert variant="warning" className="mb-0">
          <Alert.Heading>{translate('errors.header3')}</Alert.Heading>
          {[...errors400]?.map((err, index) => {
            const error: ErrorMessage = JSON.parse(err)
            return <div key={index}>{error.text}</div>
          })}
          <hr />
          <div className="d-flex justify-content-around">
            {[...errors400]?.map((err, index) => {
              const error: ErrorMessage = JSON.parse(err)

              return error.link && error.callToAction ? (
                <Button key={index} href={error.link} onClick={() => setErrors400(new Set())} variant="outline-warning">
                  {error.callToAction}
                </Button>
              ) : null
            })}
            <Button
              onClick={() => {
                setErrors400(new Set())
                const error = [...errors400]?.filter(err => err.includes('closeLink'))
                if (error?.length > 0) {
                  const link = JSON.parse(error[0])?.closeLink
                  if (link) {
                    navigate(link === '-1' ? -1 : link)
                  }
                }
              }}
              variant="outline-warning"
            >
              {translate('errors.close')}
            </Button>
          </div>
        </Alert>
      </Modal>

      <Modal show={errors500?.length > 0} centered className="px-3 text-break">
        <Alert variant="danger" className="mb-0">
          <Alert.Heading>{translate('errors.header1')}</Alert.Heading>
          <p>{translate('errors.body1')}</p>
          <hr />
          <div className="d-flex justify-content-end">
            <Button onClick={() => setErrors500([])} variant="outline-danger">
              {translate('errors.report1')}
            </Button>
          </div>
        </Alert>
      </Modal>

      <Modal show={serverDoesNotRespond} centered className="px-3 text-break">
        <Alert variant="warning" className="mb-0">
          <Alert.Heading>{translate('errors.header2')}</Alert.Heading>
          <p>{translate('errors.body2')}</p>
          <hr />
          <div className="d-flex justify-content-end">
            <Button onClick={() => window.location.reload()} variant="outline-warning">
              {translate('errors.reload')}
            </Button>
          </div>
        </Alert>
      </Modal>
    </RestErrorHandlingContext.Provider>
  )
}
